.App {

	height:98.5vh;
	background: url("img/viewer.jpg") no-repeat;
	background-size: cover;

}

.content{

	width: 300px;
  padding: 50px;
  margin: 20px;
}
